.share-picker-button {
    cursor: pointer;
}

.share-picker-icon {
    width: 32px;
}

.share-picker {
    position: absolute;
    width: 32px;
    height: 0;
    overflow-y: hidden;
    transition: height 200ms;

    > div {
        button {
            margin: 5px 0;
        }
    }
}

.share-picker.active {
    height:376px;
}