.settings-button {
    cursor: pointer;
}

.settings-icon {
    width: 32px;
}

.settings {
    background-color: #000;
    position: absolute;
    width: 94%;
    max-width:400px;
    margin: 15px 3%;
    left: 0;
    height: 0;
    overflow-y: hidden;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    transition: height 100ms;

    > div {
        button {
            margin: 5px 0;
        }
    }
}

.settings.active {
    padding: 10px;
    display: flex;
    border-radius: 8px;
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
    0 0 12px -1px rgb(0, 180, 230) inset;
    height:200px;
}