.jar-component {
    position: relative;
}
.jar {
    width: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    touch-action: none;
}

.jar.no-touch{
    pointer-events: none;
}

.jar-percent {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    pointer-events: none;
    color:#fff;
}

.jar-loading {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    pointer-events: none;
}

.jar-willing {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    pointer-events: none;
}

.jar-willing svg {
    fill: rgb(255, 255, 255);
}

.jar-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.jarOverlayMax {
    fill: red;
    opacity: 0;
    cursor: pointer;
}

.jarOverlayValue {
    fill: green;
    opacity: 0;
    cursor: pointer;
}

.jarLine {
    fill: #fff;
}

.fill {
    fill: rgba(255, 0, 138, 0.71);
}

.jar-buttons {
    margin-top:10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.jar-buttons.no-touch{
    pointer-events: none;
}

.jar-button {
    width:30%;
    padding: 5px;
    background-color: transparent;
    border-radius: 3px;
    border: 3px solid rgba(0, 40, 70, 1);
    box-shadow: 0 0 15px -1px rgba(0, 40, 70, 1),
    0 0 12px -1px rgba(0, 40, 70, 1) inset;
}
.jar-button svg{
    fill: rgb(255, 255, 255);
}

.jar-button:hover, .jar-button.selected {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
    0 0 12px -1px rgb(0, 180, 230) inset;
}

.jar-button.selected svg {
    fill: rgb(230, 0, 120);
}