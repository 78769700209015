.category {
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  border: 3px solid rgb(0, 180, 230);
  box-shadow: 0 0 15px -1px rgb(0, 180, 230),
  0 0 12px -1px rgb(0, 180, 230) inset;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.category-title {
  text-align: center;
  font-weight: 300;
  font-size: 2.9em;
  margin: 0 10px 0 0;
  color: rgba(120, 0, 50, .5);

  animation: category-text-flicker 2s linear forwards;
}

.category-title .first {
  animation: category-letter-flicker 3s linear infinite;
}

.category-jars {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.jar-wrapper {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: rgba(120, 0, 50,1);
  box-shadow: 0 0 5px -1px rgb(230, 0, 120),
  0 0 5px -1px rgb(230, 0, 120) inset;
  margin: 2%;
  padding: 10px;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.jar-wrapper-title {
  display: flex;
  color: #fff;
}

.jar-wrapper img {
  margin-left: 5px;
  width: 17px;
  height: 17px;
}

.jar-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

@keyframes category-text-flicker {
  2% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  3% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  6% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  9% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  11% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  14% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  18% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  32% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  33% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  37% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  39% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  43% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  46% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  47% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  100% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
}

@keyframes category-letter-flicker {
  2% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  3% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  6% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  9% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  11% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  14% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  18% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  32% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  33% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  37% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  39% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  40% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  100% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
}

.kinkImage {
  max-width: 140px;
}