.home-btn {
  display: flex;
  padding: 20px;
  border-radius: 8px;
  border: 3px solid rgb(0, 40, 70);
  text-decoration: none;
  margin: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;

  @media only screen and (min-width: 800px) {
    max-width: 250px;
    width: 30%;
  }

  @media only screen and (max-width: 800px) {
    padding: 10px;
    margin: 5px;
    width: 30%;
  }
}

.lineGreen {
  stroke-width: 1;
  fill: rgb(37, 80, 39);
  stroke: rgba(63, 232, 16, 0.20);
}

.lineYellow {
  stroke-width: 1;
  fill: rgb(74, 64, 35);
  stroke: rgba(255, 153, 0, 0.2);
}

.linePink {
  stroke-width: 1;
  fill: rgb(73, 35, 74);
  stroke: rgba(247, 0, 255, 0.2);
}

.lineRed {
  stroke-width: 1;
  fill: rgb(76, 40, 40);
  stroke: rgba(255, 28, 28, 0.20);
}

.lineBlue {
  stroke-width: 1;
  fill: rgb(40, 76, 76);
  stroke: rgba(28, 221, 255, 0.2);
}

.home-btn:hover {
  animation: border-flicker 2s linear forwards;
}

.home-btn:hover .lineRed {
  animation: red-flicker 2s linear forwards;
}

.home-btn:hover .lineGreen {
  animation: green-flicker 2s linear forwards;
}

.home-btn:hover .lineYellow {
  animation: yellow-flicker 2s linear forwards;
}

.home-btn:hover .lineBlue {
  animation: blue-flicker 2s linear forwards;
}

.home-btn:hover .linePink {
  animation: pink-flicker 2s linear forwards;
}

.home-btn h1 {
  font-weight: 300;
  font-size: 2.9em;
  margin: 15px 0 0;
  color: rgba(120, 0, 50, .8);
}

.home-btn:hover h1 {
  animation: text-flicker 2s linear forwards;
}

.home-btn:hover .first {
  animation: letter-flicker 3s linear infinite;
}

@keyframes text-flicker {
  2% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  3% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  6% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  9% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  11% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  14% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  18% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  32% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  33% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  37% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  39% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  43% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  46% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  47% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  100% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
}

@keyframes letter-flicker {
  2% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  3% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  6% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  9% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  11% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  14% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  18% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  32% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  33% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  37% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  39% {
    color: rgba(120, 0, 50, .5);
    text-shadow: none;
  }
  40% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
  100% {
    color: rgb(230, 0, 120);
    text-shadow: 0 0 15px rgb(230, 0, 120);
  }
}

@keyframes border-flicker {
  2% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
    0 0 12px -1px rgb(0, 180, 230) inset;
  }
  3% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  5% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
    0 0 12px -1px rgb(0, 180, 230) inset;
  }
  6% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  7% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
    0 0 12px -1px rgb(0, 180, 230) inset;
  }
  9% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  13% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
    0 0 12px -1px rgb(0, 180, 230) inset;
  }
  16% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  18% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
    0 0 12px -1px rgb(0, 180, 230) inset;
  }
  22% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  34% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
    0 0 12px -1px rgb(0, 180, 230) inset;
  }
  36% {
    border: 3px solid rgb(0, 40, 70);
    box-shadow: none;
  }
  54% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
    0 0 12px -1px rgb(0, 180, 230) inset;
  }
  100% {
    border: 3px solid rgb(0, 180, 230);
    box-shadow: 0 0 15px -1px rgb(0, 180, 230),
    0 0 12px -1px rgb(0, 180, 230) inset;
  }
}

@keyframes red-flicker {
  2% {
    fill: rgb(230, 0, 0);
  }
  3% {
    fill: rgb(70, 0, 0);
  }
  5% {
    fill: rgb(230, 0, 0);
  }
  6% {
    fill: rgb(70, 0, 0);
  }
  7% {
    fill: rgb(230, 0, 0);
  }
  9% {
    fill: rgb(70, 0, 0);
  }
  13% {
    fill: rgb(230, 0, 0);
  }
  16% {
    fill: rgb(70, 0, 0);
  }
  18% {
    fill: rgb(230, 0, 0);
  }
  22% {
    fill: rgb(70, 0, 0);
  }
  34% {
    fill: rgb(230, 0, 0);
  }
  36% {
    fill: rgb(70, 0, 0);
  }
  54% {
    fill: rgb(230, 0, 0);
  }
  100% {
    fill: rgb(230, 0, 0);
  }
}

@keyframes green-flicker {
  2% {
    fill: rgb(61, 230, 0);
  }
  3% {
    fill: rgb(7, 70, 0);
  }
  5% {
    fill: rgb(61, 230, 0);
  }
  6% {
    fill: rgb(7, 70, 0);
  }
  7% {
    fill: rgb(61, 230, 0);
  }
  9% {
    fill: rgb(7, 70, 0);
  }
  13% {
    fill: rgb(61, 230, 0);
  }
  16% {
    fill: rgb(7, 70, 0);
  }
  18% {
    fill: rgb(61, 230, 0);
  }
  22% {
    fill: rgb(7, 70, 0);
  }
  34% {
    fill: rgb(61, 230, 0);
  }
  36% {
    fill: rgb(7, 70, 0);
  }
  54% {
    fill: rgb(61, 230, 0);
  }
  100% {
    fill: rgb(61, 230, 0);
  }
}

@keyframes yellow-flicker {
  2% {
    fill: rgb(230, 199, 0);
  }
  3% {
    fill: rgb(70, 63, 0);
  }
  5% {
    fill: rgb(230, 199, 0);
  }
  6% {
    fill: rgb(70, 63, 0);
  }
  7% {
    fill: rgb(230, 199, 0);
  }
  9% {
    fill: rgb(70, 63, 0);
  }
  13% {
    fill: rgb(230, 199, 0);
  }
  16% {
    fill: rgb(70, 63, 0);
  }
  18% {
    fill: rgb(230, 199, 0);
  }
  22% {
    fill: rgb(70, 63, 0);
  }
  34% {
    fill: rgb(230, 199, 0);
  }
  36% {
    fill: rgb(70, 63, 0);
  }
  54% {
    fill: rgb(230, 199, 0);
  }
  100% {
    fill: rgb(230, 199, 0);
  }
}

@keyframes pink-flicker {
  2% {
    fill: rgb(230, 0, 226);
  }
  3% {
    fill: rgb(70, 0, 69);
  }
  5% {
    fill: rgb(230, 0, 226);
  }
  6% {
    fill: rgb(70, 0, 69);
  }
  7% {
    fill: rgb(230, 0, 226);
  }
  9% {
    fill: rgb(70, 0, 69);
  }
  13% {
    fill: rgb(230, 0, 226);
  }
  16% {
    fill: rgb(70, 0, 69);
  }
  18% {
    fill: rgb(230, 0, 226);
  }
  22% {
    fill: rgb(70, 0, 69);
  }
  34% {
    fill: rgb(230, 0, 226);
  }
  36% {
    fill: rgb(70, 0, 69);
  }
  54% {
    fill: rgb(230, 0, 226);
  }
  100% {
    fill: rgb(230, 0, 226);
  }
}

@keyframes blue-flicker {
  2% {
    fill: rgb(0, 150, 230);
  }
  3% {
    fill: rgb(0, 64, 70);
  }
  5% {
    fill: rgb(0, 150, 230);
  }
  6% {
    fill: rgb(0, 64, 70);
  }
  7% {
    fill: rgb(0, 150, 230);
  }
  9% {
    fill: rgb(0, 64, 70);
  }
  13% {
    fill: rgb(0, 150, 230);
  }
  16% {
    fill: rgb(0, 64, 70);
  }
  18% {
    fill: rgb(0, 150, 230);
  }
  22% {
    fill: rgb(0, 64, 70);
  }
  34% {
    fill: rgb(0, 150, 230);
  }
  36% {
    fill: rgb(0, 64, 70);
  }
  54% {
    fill: rgb(0, 150, 230);
  }
  100% {
    fill: rgb(0, 150, 230);
  }
}

.share-link-button {
  max-width: 32px;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}