.fixed-header {
  position: sticky;
  top: 0;
  left: 0;
  padding: 10px;
  height: 140px;
  background-color: #000;
  z-index: 1000;

  @media only screen and (min-width: 800px) {
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    height: 60px;
  }
}

.fixed-header-container {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 3px solid rgb(0, 180, 230);
  box-shadow: 0 0 15px -1px rgb(0, 180, 230),
  0 0 12px -1px rgb(0, 180, 230) inset;

  @media only screen and (min-width: 800px) {
    flex: 1;
    height: 100%;
    margin: 0 10px;
  }
}

.fixed-header-container:nth-last-child(1) {
  margin-top: 10px;
  @media only screen and (min-width: 800px) {
    margin-top: 0;
  }
}


.fixed-header-view {
  position: sticky;
  top: 0;
  left: 0;
  padding: 10px;
  height: 60px;
  background-color: #000;
  z-index: 1000;
}

.fixed-view-header-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 3px solid rgb(0, 180, 230);
  box-shadow: 0 0 15px -1px rgb(0, 180, 230),
  0 0 12px -1px rgb(0, 180, 230) inset;
}

.header-back {
  flex: 1;
}

.header-back a {
  text-decoration: none;
  color: #fff;
}

.color-wrapper {
  flex: 3;
}

.shareIcons-wrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.group-jars {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.button-link {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  color: #fff;

  &:focus {
    outline: none;
  }
}