.color-picker {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.color {
    width:20px;
    height:20px;
    border-radius: 20px;
    cursor: pointer;
}

.color.selected {
    border: 2px solid #fff;
}