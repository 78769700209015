.language-wrapper {
  text-align: right;

  button {
    background-color: transparent;
    border: 0;
    margin: 5px 5px 0 5px;
    cursor: pointer;
    &:focus{
      outline: none;
    }

    &.active {
      border: 1px solid rgb(0, 180, 230);
      box-shadow: 0 0 15px -1px rgba(0, 180, 230, 0.44),
      0 0 12px -1px rgba(0, 180, 230, 0.6) inset;
    }
  }
}

@media only screen and (max-width: 470px) {
  .language-wrapper {
    button {
      img {
        width:20px;
      }
    }
  }
}