img.kofiimg {
    display: initial !important;
    vertical-align: middle;
    height: 13px !important;
    width: 20px !important;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    margin: 0 5px 3px 0;
    content: url('https://ko-fi.com/img/cup-border.png')
}

.kofiimg:after {
    vertical-align: middle;
    height: 25px;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    margin-top: 0;
    margin-right: 6px;
    margin-left: 0;
    margin-bottom: 4px !important;
    content: url('https://ko-fi.com/img/whitelogo.svg')
}

.btn-container {
    display: inline-block !important;
    white-space: nowrap;
    min-width: 160px;
}

span.kofitext {
    color: #fff !important;
    letter-spacing: -0.15px !important;
    vertical-align: middle;
    line-height: 33px !important;
    padding: 0;
    text-align: center;
    text-decoration: none !important;
    text-shadow: 0 1px 1px rgba(34, 34, 34, 0.05);
}

.kofitext a {
    color: #fff !important;
    text-decoration: none !important;
}

.kofitext a:hover {
    color: #fff !important;
    text-decoration: none
}

a.kofi-button {
    box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
    line-height: 36px !important;
    min-width: 150px;
    display: inline-block !important;
    padding: 2px 12px !important;
    text-align: center !important;
    color: #fff;
    cursor: pointer;
    overflow-wrap: break-word;
    vertical-align: middle;
    font-family: 'Quicksand', Helvetica, Century Gothic, sans-serif !important;
    text-decoration: none;
    text-shadow: none;
    font-size: 14px !important;

    border-radius: 8px;
    border: 3px solid rgb(0, 40, 70);
    animation: border-flicker-support 4s linear infinite;
}

a.kofi-button:visited {
    color: #fff !important;
    text-decoration: none !important
}

a.kofi-button:hover {
    opacity: .85;
    color: #f5f5f5 !important;
    text-decoration: none !important
}

a.kofi-button:active {
    color: #f5f5f5 !important;
    text-decoration: none !important
}

.kofitext img.kofiimg {
    height: 15px !important;
    width: 22px !important;
    display: initial;
    animation: kofi-wiggle 3s infinite;
}

@keyframes kofi-wiggle {
    0% {
        transform: rotate(0) scale(1)
    }
    60% {
        transform: rotate(0) scale(1)
    }
    75% {
        transform: rotate(0) scale(1.12)
    }
    80% {
        transform: rotate(0) scale(1.1)
    }
    84% {
        transform: rotate(-10deg) scale(1.1)
    }
    88% {
        transform: rotate(10deg) scale(1.1)
    }
    92% {
        transform: rotate(-10deg) scale(1.1)
    }
    96% {
        transform: rotate(10deg) scale(1.1)
    }
    100% {
        transform: rotate(0) scale(1)
    }
}

@keyframes border-flicker-support {
    2% {
        border: 3px solid rgb(0, 180, 230);
        box-shadow: 0 0 15px -1px rgb(0, 180, 230),
        0 0 12px -1px rgb(0, 180, 230) inset;
    }
    3% {
        border: 3px solid rgb(0, 40, 70);
        box-shadow: none;
    }
    5% {
        border: 3px solid rgb(0, 180, 230);
        box-shadow: 0 0 15px -1px rgb(0, 180, 230),
        0 0 12px -1px rgb(0, 180, 230) inset;
    }
    6% {
        border: 3px solid rgb(0, 40, 70);
        box-shadow: none;
    }
    7% {
        border: 3px solid rgb(0, 180, 230);
        box-shadow: 0 0 15px -1px rgb(0, 180, 230),
        0 0 12px -1px rgb(0, 180, 230) inset;
    }
    9% {
        border: 3px solid rgb(0, 40, 70);
        box-shadow: none;
    }
    13% {
        border: 3px solid rgb(0, 180, 230);
        box-shadow: 0 0 15px -1px rgb(0, 180, 230),
        0 0 12px -1px rgb(0, 180, 230) inset;
    }
    16% {
        border: 3px solid rgb(0, 40, 70);
        box-shadow: none;
    }
    18% {
        border: 3px solid rgb(0, 180, 230);
        box-shadow: 0 0 15px -1px rgb(0, 180, 230),
        0 0 12px -1px rgb(0, 180, 230) inset;
    }
    22% {
        border: 3px solid rgb(0, 40, 70);
        box-shadow: none;
    }
    34% {
        border: 3px solid rgb(0, 180, 230);
        box-shadow: 0 0 15px -1px rgb(0, 180, 230),
        0 0 12px -1px rgb(0, 180, 230) inset;
    }
    36% {
        border: 3px solid rgb(0, 40, 70);
        box-shadow: none;
    }
    54% {
        border: 3px solid rgb(0, 180, 230);
        box-shadow: 0 0 15px -1px rgb(0, 180, 230),
        0 0 12px -1px rgb(0, 180, 230) inset;
    }
    100% {
        border: 3px solid rgb(0, 180, 230);
        box-shadow: 0 0 15px -1px rgb(0, 180, 230),
        0 0 12px -1px rgb(0, 180, 230) inset;
    }
}

@media only screen and (max-width: 470px) {
    a.kofi-button {
        min-width:120px;
        line-height: 20px !important;
        font-size: 12px !important;
    }
}