// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

:root {
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;

  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-shadow: inset 0 0 200px black;
  background: rgb(15, 15, 15) url("assets/images/redbg.png");
}

#root {
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.loadingText {
  font-size: 20px;
  color: #fff;
  text-align: center;
  padding: 50px;
}

.home-wrapper {
  margin: 0 auto;
  max-width: 1024px;
}

.home-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 1%;
}

.footer-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
  margin-top: 20px;

  .resetSessions {
    flex: 2;
    text-align: left;

    .btnReset {
      color: #fff;
      background: none!important;
      border: none;
      padding: 0!important;
      cursor: pointer;
    }
  }

  .support-button {
    flex: 2;
    text-align: right;
  }

  .shareIcons-wrapper {
    flex: 1;
    align-items: center;
    justify-content: center;
    > div {
      button {
        margin: 0 5px;
        @media only screen and (max-width: 425px) {
          margin: 0;
        }
      }
    }
  }
}


.logo {
  padding: 1% 10%;
  margin: 0 auto;
  max-width: 800px;
}

.lineHomePink {
  stroke-width: 1;
  fill: rgb(73, 35, 74);
  stroke: rgba(247, 0, 255, 0.2);
  animation: lineHomePink-flicker 2s linear forwards;
}

@keyframes lineHomePink-flicker {
  2% {
    fill: rgb(230, 0, 226);
  }
  3% {
    fill: rgb(70, 0, 69);
  }
  5% {
    fill: rgb(230, 0, 226);
  }
  6% {
    fill: rgb(70, 0, 69);
  }
  7% {
    fill: rgb(230, 0, 226);
  }
  9% {
    fill: rgb(70, 0, 69);
  }
  13% {
    fill: rgb(230, 0, 226);
  }
  16% {
    fill: rgb(70, 0, 69);
  }
  18% {
    fill: rgb(230, 0, 226);
  }
  22% {
    fill: rgb(70, 0, 69);
  }
  34% {
    fill: rgb(230, 0, 226);
  }
  36% {
    fill: rgb(70, 0, 69);
  }
  54% {
    fill: rgb(230, 0, 226);
  }
  100% {
    fill: rgb(230, 0, 226);
  }
}

.lineHomeWhite {
  stroke-width: 1;
  fill: rgb(90, 90, 90);
  stroke: rgba(112, 112, 112, 0.2);
  animation: lineHomeWhite-flicker 2s linear forwards;
}

@keyframes lineHomeWhite-flicker {
  2% {
    fill: rgb(229, 229, 229);
  }
  3% {
    fill: rgb(71, 71, 71);
  }
  5% {
    fill: rgb(229, 229, 229);
  }
  6% {
    fill: rgb(71, 71, 71);
  }
  7% {
    fill: rgb(229, 229, 229);
  }
  9% {
    fill: rgb(71, 71, 71);
  }
  13% {
    fill: rgb(229, 229, 229);
  }
  16% {
    fill: rgb(71, 71, 71);
  }
  18% {
    fill: rgb(229, 229, 229);
  }
  22% {
    fill: rgb(71, 71, 71);
  }
  34% {
    fill: rgb(229, 229, 229);
  }
  36% {
    fill: rgb(71, 71, 71);
  }
  54% {
    fill: rgb(229, 229, 229);
  }
  100% {
    fill: rgb(229, 229, 229);
  }
}

.elementFlicker {
  stroke-width: 1;
  fill: rgb(90, 90, 90);
  stroke: rgba(112, 112, 112, 0.2);
  animation: aaaaaaaaaaaa 3s linear infinite;
}

@keyframes aaaaaaaaaaaa {
  2% {
    fill: rgb(227, 227, 227);
  }
  3% {
    fill: rgba(100, 100, 100, .5);
  }
  6% {
    fill: rgb(227, 227, 227);
  }
  9% {
    fill: rgba(100, 100, 100, .5);
  }
  11% {
    fill: rgb(227, 227, 227);
  }
  14% {
    fill: rgba(100, 100, 100, .5);
  }
  18% {
    fill: rgb(227, 227, 227);
  }
  32% {
    fill: rgb(227, 227, 227);
  }
  33% {
    fill: rgba(100, 100, 100, .5);
  }
  37% {
    fill: rgb(227, 227, 227);
  }
  39% {
    fill: rgba(100, 100, 100, .5);
  }
  40% {
    fill: rgb(227, 227, 227);
  }
  100% {
    fill: rgb(227, 227, 227);
  }
}

.disqus-comment-wrapper {
  padding:10px;
}

@media only screen and (max-width: 800px) {
  .logo {
    padding: 0 25%;
  }

  .home-buttons {
    justify-content: center;
    align-items: stretch;
    flex-direction: row;
  }

  .home-buttons > .home-btn {
    padding: 10px;
    margin: 5px;
    max-width: 180px;
  }

  .home-buttons > .home-btn h1 {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 470px) {
  .logo {
    padding: 0 5%;
  }
}

@media only screen and (max-width: 320px) {
  .home-buttons > .home-btn h1 {
    font-size: 1.0rem;
  }
}